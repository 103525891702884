<template>
  <el-drawer
    v-model="isShowNotificationDrawer"
    :show-close="false"
    modal-class="elv-report-notification-overlay"
    class="elv-report-notification-drawer"
    :before-close="onCheckDrawerStatus"
    :size="450"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-report-notification-drawer-header__title">
        {{ t('report.resolutionCenter') }}<span>{{ issueDataList.length }}</span>
      </h4>

      <SvgIcon
        name="close"
        width="20"
        height="20"
        class="elv-report-notification-drawer-header__close"
        @click="onCheckDrawerStatus"
      ></SvgIcon>
    </template>
    <el-scrollbar>
      <ul v-if="issueDataList.length" class="elv-report-notification-list">
        <li v-for="(item, index) in issueDataList" :key="index" class="elv-report-notification-item">
          <div v-if="item.type === 'DUPLICATE'" class="elv-report-notification-item-content">
            <SvgIcon name="resolution-possible-duplicates" width="16" height="16" />
            <div class="elv-report-notification-item-content__text">
              <p>{{ t('report.possibleDuplicates') }}</p>
              <p>
                {{
                  item.count > 1
                    ? t('report.possibleDuplicatesInfo', { total: item.count })
                    : t('report.possibleDuplicateInfo', { total: item.count })
                }}
              </p>
            </div>
          </div>

          <div v-if="item.type === 'UNKNOW_EVENT'" class="elv-report-notification-item-content">
            <SvgIcon name="resolution-unknown-event" width="16" height="16" />
            <div class="elv-report-notification-item-content__text">
              <p>{{ t('report.unknownEvent') }}</p>
              <p>
                {{
                  item.count > 1
                    ? t('report.unknownEventsInfo', { total: item.count })
                    : t('report.unknownEventInfo', { total: item.count })
                }}
              </p>
            </div>
          </div>

          <div v-if="item.type === 'INVENTORY_OVERSPEND'" class="elv-report-notification-item-content">
            <SvgIcon name="resolution-unknown-event" width="16" height="16" />
            <div class="elv-report-notification-item-content__text">
              <p>{{ t('report.disposalExceedsBalance', { currency: item.currency }) }}</p>
              <p>
                {{ t('report.disposalExceedsBalanceInfo', { currency: item.currency }) }}
              </p>
            </div>
          </div>

          <div
            v-if="
              item.type === 'CASH_FLOW_STATEMENT' ||
              item.type === 'BALANCE_SHEET' ||
              item.type === 'RESTRICTIONS_OF_CRYPTO_ASSETS' ||
              item.type === 'CRYPTO_ASSETS_ROLL_FORWARD'
            "
            class="elv-report-notification-item-content"
          >
            <SvgIcon name="resolution-report-error" width="16" height="16" />
            <div class="elv-report-notification-item-content__text">
              <p>
                {{ t('report.errorReport', { sheet: errorReportTitle(item.type) }) }}
              </p>
              <p>{{ t('report.errorReportInfo') }}</p>
            </div>
          </div>

          <div class="elv-report-notification-item__button" @click="jumpIssueDetail(item)">
            {{ t('report.resolve') }}
          </div>
        </li>
      </ul>
    </el-scrollbar>
  </el-drawer>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { emitter } from '@/lib/mitt'
import { ReportIssueType } from '#/ReportsTypes'
import { useReportStore } from '@/stores/modules/reports/index'

const { t } = useI18n()
// eslint-disable-next-line no-unused-vars
const router = useRouter()
const reportStore = useReportStore()

const { issueList } = toRefs(reportStore)
const isShowNotificationDrawer = ref(false)

const onCheckDrawerStatus = () => {
  isShowNotificationDrawer.value = !isShowNotificationDrawer.value
}

const issueDataList = computed(() => {
  return issueList.value.filter((item) => item.type !== 'FOR_REVIEW')
})

const errorReportTitle = computed(() => {
  return (type: string) => {
    let title = ''
    switch (type) {
      case 'BALANCE_SHEET':
        title = t('project.nav.balanceSheet')
        break
      case 'INCOME_STATEMENT':
        title = t('project.nav.incomeStatement')
        break
      case 'CASH_FLOW_STATEMENT':
        title = t('project.nav.cashFlowStatement')
        break
      case 'RESTRICTIONS_OF_CRYPTO_ASSETS':
        title = t('project.nav.RestrictionsCryptoAssets')
        break
      case 'CRYPTO_ASSETS_ROLL_FORWARD':
        title = t('project.nav.rollForward')
        break
      default:
        break
    }
    return title
  }
})

const jumpIssueDetail = (item: ReportIssueType) => {
  switch (item.type) {
    case 'DUPLICATE':
      router.push({ name: 'reports-transactions-duplicates' })
      break
    case 'BALANCE_SHEET':
      router.push({ name: 'reports-balance-sheet' })
      break
    case 'INCOME_STATEMENT':
      router.push({ name: 'reports-income-statement' })
      break
    case 'CASH_FLOW_STATEMENT':
      router.push({ name: 'reports-cash-flow-statement' })
      break
    case 'RESTRICTIONS_OF_CRYPTO_ASSETS':
      router.push({ name: 'reports-restrictions' })
      break
    case 'CRYPTO_ASSETS_ROLL_FORWARD':
      router.push({ name: 'reports-roll-forward' })
      break
    case 'INVENTORY_OVERSPEND':
      window.open(
        'https://docs.elven.com/english/guides-and-tutorials/generate-financial-statements#iv.-common-reasons-for-report-errors',
        '_blank'
      )
      break
    default:
      router.push({ name: 'reports-transactions-for-review', query: { noSet: 1 } })
      reportStore.transactionFilter = {}
      reportStore.transactionFilter.categoryNos = ['00000']
      emitter.emit('resetList')
      break
  }
  onCheckDrawerStatus()
}

defineExpose({ onCheckDrawerStatus })
</script>

<style lang="scss" scoped>
.elv-report-notification-drawer {
  .elv-report-notification-drawer-header__title {
    font-family: 'Plus Jakarta Sans';
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #0e0f11;
    margin: 0;

    span {
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #0e0f11;
      margin-left: 6px;
    }
  }

  .elv-report-notification-drawer-header__close {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
.elv-report-notification-drawer {
  .el-drawer__header {
    padding: 26px 0px 18px;
    margin: 0 24px;
    border-bottom: 1px solid #edf0f3;
    height: 64px;
    box-sizing: border-box;
  }

  .el-drawer__body {
    padding: 16px 20px;
    margin-bottom: 16px;
  }

  .el-drawer__footer {
    text-align: left;
    padding: 0px 24px 24px;
  }

  .elv-report-notification-item {
    padding: 16px 8px;
    width: 100%;
    min-height: 67px;
    box-sizing: border-box;
    border-bottom: 1px solid #edf0f3;
    display: flex;
    justify-content: space-between;

    .elv-report-notification-item-content {
      display: flex;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    .elv-report-notification-item-content__text {
      display: flex;
      flex-direction: column;
      margin-left: 8px;
      max-width: 260px;

      p {
        font-family: 'Plus Jakarta Sans';
        font-weight: 400;
        font-size: 11px;
        line-height: 14px;
        color: #636b75;

        &:first-child {
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          color: #0e0f11;
          margin-bottom: 5px;
        }
      }
    }

    .elv-report-notification-item__button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 69px;
      height: 33px;
      background: #f1f5ff;
      border-radius: 3px;
      font-family: 'PingFang SC';
      font-weight: 600;
      font-size: 12px;
      line-height: 17px;
      color: #0e0f11;
      cursor: pointer;

      &:hover {
        background: #1753eb;
        color: #fff;
      }
    }
  }
}
</style>
