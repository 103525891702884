<template>
  <div v-loading.fullscreen.lock="loading" class="elv-report-detail">
    <div v-if="isShowDetailHeader" class="elv-report-header">
      <DetailHeader id="header" />
    </div>

    <div class="elv-report-tab-button">
      <div
        v-if="showNotification"
        class="elv-report-tab-button__notification"
        :class="{ 'is-disabled': !issueDataList.length }"
        @click="onCheckResolutionCenter"
      >
        <SvgIcon name="report-notification" width="16" height="16" />
        <span>{{ issueDataList.length }}</span>
      </div>
    </div>
  </div>
  <div id="elv-report-detail-container">
    <router-view />
  </div>

  <ResolutionCenterOverlay ref="resolutionCenterRef" />
</template>

<script setup lang="ts">
import utils from '@/lib/utils'
import { useI18n } from 'vue-i18n'
import WaterMark from 'l-watermark'
import { ElMessage } from 'element-plus'
import { find, isEmpty, includes } from 'lodash-es'
import DetailHeader from './components/DetailHeader.vue'
import { useGlobalStore } from '@/stores/modules/global'
import { useReportStore } from '@/stores/modules/reports/index'
import { useMenuGlobalStore } from '@/stores/modules/global/menu'
import { useUserGlobalStore } from '@/stores/modules/global/user'
import ResolutionCenterOverlay from './components/ResolutionCenterOverlay.vue'

const { t } = useI18n()
const router = useRouter()
const globalStore = useGlobalStore()
const reportStore = useReportStore()
const menuGlobalStore = useMenuGlobalStore()
const userGlobalStore = useUserGlobalStore()
const { loading, issueList } = toRefs(reportStore)
const { user } = storeToRefs(userGlobalStore)
const route = useRoute()
const isShowDetailHeader = ref(false)
const resolutionCenterRef = ref()
const privateProjectWaterMark: any = ref(null)
const removeWaterMark = ref(false)
const entityId = computed(() => {
  return Number(route.params?.entityId)
})

const issueDataList = computed(() => {
  return issueList.value.filter((item) => item.type !== 'FOR_REVIEW')
})

const headerWidth = computed(() => {
  return utils.isMobile() || !menuGlobalStore.isFixedMenu ? 'calc(100vw - 48px)' : 'calc(100vw - 179px)'
})

const showNotification = computed(() => {
  return route.name !== 'reports-dashboard'
})

const currentEntityPermission = computed(() => {
  return reportStore.entityPermission()
})

const onCheckResolutionCenter = () => {
  if (!currentEntityPermission.value?.resolutionCenter?.view) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (issueDataList.value.length) {
    resolutionCenterRef.value?.onCheckDrawerStatus()
  }
}

watch(
  [() => entityId.value],
  async () => {
    try {
      if (entityId.value && !route.params?.tableId) {
        isShowDetailHeader.value = false
        await reportStore.projectInit(entityId.value)
        isShowDetailHeader.value = true
      }
    } catch (error: any) {
      isShowDetailHeader.value = true
      if (error?.code === 10001 || error?.code === 19424) {
        if (globalStore.projectList.length) {
          router.replace({ name: 'reports', params: { entityId: globalStore.entityListAll[0]?.entityId } })
        } else {
          router.replace({ name: 'landingPage' })
        }
      } else if (error?.code === 401) {
        router.push({ name: 'login' })
      }
    }
  },
  { immediate: true }
)

watchEffect(() => {
  if (reportStore.entityDetail.entityId !== 0) {
    if (route.params?.entityId && !route.params?.projectId && !isEmpty(currentEntityPermission.value)) {
      if (route.matched.length === 2) {
        router.push({ name: 'reports' })
      } else if (route.name !== 'reports-balance-sheet') {
        const tableRouteNameList = [
          'reports-balance-sheet',
          'reports-income-statement',
          'reports-cash-flow-statement',
          'reports-significant-holdings',
          'reports-restrictions',
          'reports-roll-forward'
        ]
        if (includes(tableRouteNameList, route.name) && !currentEntityPermission.value?.report?.view) {
          ElMessage.warning(t('message.noPermission'))
          return
        }
        switch (route.name) {
          case 'reports-sources-detail':
            if (!currentEntityPermission.value?.dataSource?.view) {
              ElMessage.warning(t('message.noPermission'))
              return
            }
            break
          case 'reports-general-ledger':
            if (!currentEntityPermission.value?.generalLedger?.view) {
              ElMessage.warning(t('message.noPermission'))
              return
            }
            break
          case 'reports-sources-accounts':
            if (!currentEntityPermission.value?.entityAccount?.view) {
              ElMessage.warning(t('message.noPermission'))
              return
            }
            break
          case 'reports-contacts':
            if (!currentEntityPermission.value?.contact?.view) {
              ElMessage.warning(t('message.noPermission'))
              return
            }
            break
          case 'reports-transactions-for-review' ||
            'reports-transactions-duplicates' ||
            'reports-transactions-categorized':
            if (!currentEntityPermission.value?.transaction?.viewList) {
              ElMessage.warning(t('message.noPermission'))
              return
            }
            break
          case 'reports-rules':
            if (!currentEntityPermission.value?.configRule?.view) {
              ElMessage.warning(t('message.noPermission'))
              return
            }
            break
          default:
            break
        }
        router.push({ name: String(route.name), query: route.query })
      } else {
        if (!currentEntityPermission.value?.report?.view) {
          ElMessage.warning(t('message.noPermission'))
          return
        }
        router.push({ name: 'reports-balance-sheet' })
      }
      nextTick(() => {
        const target: any = document.getElementById('elv-report-detail-container')
        if (!isEmpty(find(globalStore.entityListAll, { entityId: Number(route.params?.entityId) }))) {
          if (isEmpty(privateProjectWaterMark.value)) {
            // 添加水印
            privateProjectWaterMark.value = WaterMark.page({
              target,
              text: `${user.value?.email}`,
              color: 'rgba(0, 0, 0, 0.1)',
              fontSize: 14,
              cSpace: 100,
              vSpace: 30,
              onchange: (a: any) => {
                if (removeWaterMark.value) {
                  a?.remove()
                  privateProjectWaterMark.value = null
                }
              }
            })
            removeWaterMark.value = false
          }
        } else {
          const lwatermark = document.getElementsByClassName('l-watermark')[0]
          privateProjectWaterMark.value?.remove()
          removeWaterMark.value = true
          lwatermark?.remove()
          privateProjectWaterMark.value = null
        }
      })
    }
  }
})

onBeforeUnmount(() => {
  if (!isEmpty(find(globalStore.entityListAll, { entityId: Number(route.params?.entityId) }))) {
    // 移除水印
    privateProjectWaterMark.value?.remove()
  }
})
</script>

<style lang="scss">
.elv-layout-main {
  padding: 0 !important;
}

.elv-report-detail {
  height: 48px;
  position: relative;
  display: flex;
  justify-content: space-between;

  .elv-report-header {
    width: v-bind('headerWidth');
    padding-left: 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #dde1e6;
  }

  .elv-report-tab-button {
    position: absolute;
    right: 20px;
    height: 48px;
    display: flex;
    align-items: center;

    .elv-report-tab-button__notification {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 43px;
      height: 24px;
      background: #2f63eb;
      border-radius: 20px;
      cursor: pointer;

      span {
        margin-left: 4px;
        font-family: 'Barlow';
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #ffffff;
      }

      svg {
        fill: #fff;
      }

      &.is-disabled {
        cursor: not-allowed;
        background: #edf0f3;

        svg {
          fill: #d0d4d9;
        }

        span {
          color: #d0d4d9;
        }
      }

      &:not(.is-disabled):hover {
        background: #1753eb;
      }
    }

    .elv-report-tab-button__setting {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24px;
      cursor: pointer;
      margin-left: 12px;
    }
  }
}
</style>
